<template>
  <div class="header">
    <h1 class="alignleft ">James</h1>
    <h1 class="alignleft ">Slomka</h1>
    <h2 class="alignright  delayheader"  style="text-transform: uppercase"></h2>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <div class="content">

<!--      <h1>About<br />Me</h1>-->
<!--      <div class="photo"></div>-->
<!--      <p>-->
<!--        Technical Lead, Staff Software Developer & Engineering Manager at <a href="https://www.ssense.com">SSENSE</a>, a luxury fashion e-commerce retailer. Currently working on the Checkout Team - enabling customers to purchase with ease by offering a reliable, personalized, and frictionless checkout experience.-->
<!--      </p>-->
    </div>
    <h1 class="alignright delaycontent" >Contact</h1>
    <div class="contact-list  delaycontent">
      <div class="contact"><a href="mailto:me@jamesslomka.com">Email</a></div>
      <div class="contact"><a href="https://www.github.com/jamesslomka" target="blank">GitHub</a></div>
      <div class="contact"><a href="https://www.linkedin.com/in/jamesslomka" target="blank">LinkedIn</a></div>
      <div class="contact"><a href="https://twitter.com/jamesslomka" target="blank">Twitter</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      about: ''
    }
  }
}
</script>

<style >
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    padding:0;
    margin:0;
    background: #000000;
    font-size: 16px;
  }
  ::selection {
    color: rgba(0,0,0,.99);
    background: rgba(255,255,255,.99);
  }
  ::-moz-selection {
    color: rgba(0,0,0,.99);
    background: rgba(255,255,255,.99);
  }
  /* ----- intro ----- */
  .header {
    margin-top: 5vh;
  }
  @media screen and (max-width: 767px) {
    .header {
      margin-top: 20vh;
    }
  }
  h1 {
    font-family: "Helvetica";
    font-size: 200px;
    font-size: 13.5vw;
    line-height: 80%;
    color: #FFFFFF;
    text-transform: uppercase;
    padding-right: 0.5%;
  }
  @media screen and (max-width: 767px) {
    h1 {
      font-size: 20vw;
    }
  }
  .alignleft {
    text-align: left;
  }
  .alignright {
    text-align: right;
  }
  h2 {
    font-size: 35px;
    line-height: 115%;
    color: #FFFFFF;
    margin: 10vh 0 20vh 0vh;
    max-width: 99.5%;
    overflow: hidden;
  }
  @media screen and (max-width: 767px) {
    h2 {
      max-width: 100%;
      font-size: 30px;
    }
  }
  /* ----- animation ----- */
  .animate {
    -webkit-animation:animate .5s cubic-bezier(0.2, 0.6, 0.2, 1);
    -moz-animation:animate .5s cubic-bezier(0.2, 0.6, 0.2, 1);
    animation:animate .5s cubic-bezier(.59, .01, .28, 1);
    -webkit-animation-fill-mode:backwards;
    -moz-animation-fill-mode:backwards;
    animation-fill-mode:backwards;
  }
  @-webkit-keyframes animate {
    0% {opacity: 0;-webkit-transform: translateY(-50px);}
    100% {opacity: 1;-webkit-transform: translateY(0%);}
  }

  @keyframes animate {
    0% {opacity: 0;transform: translateY(-50px);}
    100% {opacity: 1;transform: translateY(0%);}
  }
  .delayheader { -webkit-animation-delay: .3s; animation-delay: .3s;}
  .delaycontent { -webkit-animation-delay: 0.8s; animation-delay: 0.8s;}
  /* ----- content ----- */
  .photo {
    background-image: url(https://static.jamesslomka.com/me.jpeg);
    background-repeat: no-repeat;
    background-size: contain;
    /* 	height: 40vw; */
    height: 69vh;
    margin: -35vh 0 0 -70vh;
    /*	margin: auto 0 0 -60%;*/
  }
  @media screen and (max-width: 767px) {
    .photo {
      height: 90vw;
      margin: 10vh 0;
    }
  }
  .content {
    max-width: 40%;
    margin-top: 10vh;
    margin-bottom: 20vh;
    margin-left: 50%;
  }
  @media screen and (max-width: 767px) {
    .content {
      max-width: 100%;
      margin-top: 0;
      margin-left: 0;
    }
  }
  p {
    font-size: 24px;
    color: #FFFFFF;
    line-height: 125%;
    margin-bottom: 4vh;
    overflow: hidden;
  }
  p a, p a:after, p a:visited {
    font-size: 24px;
    color: #FFFFFF;
    line-height: 125%;
    cursor: pointer;
  }
  a {
    padding-bottom: 3%;
  }

  /* ----- contact ----- */
  .contact-list {
    margin-top: 10vh;
  }
  .contact {
    font-size: 60px;
    color: #FFFFFF;
    line-height: 133%;
  }
  @media screen and (max-width: 767px) {
    .contact {
      font-size: 10vw;
    }
  }
  .contact a, a:after, a:visited {
    color: #FFFFFF;
    cursor: pointer;
  }
</style>
